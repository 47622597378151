import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import './style/normalize.css'
import './style/common.css'
import animated from 'animate.css'
import "../node_modules/swiper/swiper-bundle.css"

Vue.config.productionTip = false
Vue.use(animated)
Vue.prototype.$http = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
