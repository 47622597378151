<template>
  <div v-if="config.show" class="correctDialog">
    <div class="result-container animate__animated animate__faster" :class="[config.show ? 'animate__zoomIn': 'animate__zoomOut']">
			<p class="title">恭喜你，回答正确，获得5积分</p>
      <div class="status">
        <img src="../assets/correct-circle.png" alt="">
      </div>
      <div class="script-ip">
        <img src="../assets/script-ip.png" alt="">
      </div>
      <div class="btn">
        <img src="../assets/keep-punch.png" @click="onKeepPunch" alt="">
      </div>
      <!-- 关闭按钮 -->
      <div class="close-btn" @click="config.show = false">
        <img src="../assets/close-btn-w.png" alt="">
      </div>
		</div>
  </div>
</template>

<script>
  export default {
    props: {
      config: ''
    },
    data () {
      return {
        
      }
    },
    methods: {
      onKeepPunch() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .correctDialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .result-container {
      margin-top: 100px;
      padding: 70px 30px;
      position: relative;
      width: 880px;
      height: 520px;
      background: #172221;
      box-sizing: border-box;
      border: 6px solid #B3D7FF;
      border-radius: 16px;
      text-align: center;
      .title {
        font-size: 26px;
      }
      .status {
        margin: 50px 0;
      }
      .script-ip {
        position: absolute;
        top: 100px;
      }
    }
    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
</style>