<template>
  <div class="game-center">
    <!-- 地图 -->
    <div id="container"></div>
		<div class="home-btn" @click="toHome">
			<img src="../assets/home-icon.png" alt="">
		</div>
		<div class="seal">
			<img src="../assets/seal.png" alt="">
		</div>
		<div class="operation-bar">
			<div @click="openPunch">
				<img src="../assets/punch.png" alt="">
			</div>
			<div @click="openPunch">
				<img src="../assets/task.png" alt="">
			</div>
			<div @click="onLocation">
				<img src="../assets/dingwei.png" alt="">
			</div>
		</div>
		<div class="script-name">
			<img :src="scriptDetail.scriptIpImageUrl" style="height:260px;width: 200px;" alt="">
			<div class="name">
				<p>{{ scriptDetail.scriptName }}</p>
				<img src="../assets/script-name-bg.png" style="width:480px;height:200px;margin-left: 30px;" alt="">
			</div>
		</div>

		<!-- 打卡弹窗 -->
		<div class="punch-dialog" v-if="punchShow">
			<div class="punch-container animate__animated animate__faster" :class="[punchShow? 'animate__zoomIn': 'animate__zoomOut']">
				<div class="title">{{ nextPlace.scriptPlaceResultBo.placeName }}</div>
				<div class="content">
					<div class="poster">
						<div style="width: 130px;height: 130px;">
							<img :src="nextPlace.scriptPlaceResultBo.placeImages[0]" style="height: 100%;width:100%" alt="">
						</div>
						
						<div class="task">
							<p>任务: {{ nextPlace.questionResult.taskCategory == 1 ? '知识问答':  nextPlace.questionResult.taskCategory == 2? '拍照任务': '趣味任务'}}</p>
							<p>奖励: {{ nextPlace.taskIntegral }}积分</p>
						</div>
					</div>
					<div class="btn">
						<div class="item" v-show="!nextPlace.scriptPlaceResultBo.isCompletePunch" ><img @click="onPunch" src="../assets/punch-w.png" alt=""></div>
						<div class="item" v-show="nextPlace.scriptPlaceResultBo.isCompletePunch"><img src="../assets/punch-y.png" alt=""></div>
						<div class="item" v-show="!nextPlace.scriptPlaceResultBo.isCompleteTask"><img src="../assets/challeng-w.png" alt=""></div>
						<div class="item" v-show="nextPlace.scriptPlaceResultBo.isCompleteTask"><img @click="toAnswerPage" src="../assets/challeng.png" alt=""></div>
					</div>
				</div>
				<!-- 关闭按钮 -->
				<div class="close-btn" @click="punchShow = false">
					<img src="../assets/close-btn-w.png" alt="">
				</div>
			</div>
		</div>
		<!-- 剧情面板 -->
		<div class="storyline-dialog animate__animated animate__faster" v-if="punchShow" :class="[punchShow ? 'animate__slideInDown': 'animate__slideOutUp']">
			<div class="title">{{ nextPlace.scriptPlaceResultBo.placeName }}</div>
			<div class="sub-title">剧情简介:</div>
			<div class="scroll-wrap" style="height: 640px; overflow-y: scroll;">
        <div class="content">{{ currentPlaceScriptLine }}</div>
      </div>
		</div>
		<audio id="script_audio" :src="currentPlaceScriptVoice"></audio>
		<!-- 地点面板 -->
		<PlaceDialog :config="placeDialogDatas" @gotoHere="toCurrentPlace"></PlaceDialog>

		<!-- 答题提示 -->
		<div v-show="showTips" class="ansTips animate__animated" :class="[showTips? 'animate__fadeInUp' : 'animate__fadeOutDown']">
			恭喜你，打卡成功~
		</div>
		<ComplateDialog :config="complateDatas"></ComplateDialog>
  </div>
</template>

<script>
	import PlaceDialog from './PlaceDialog'
	import ComplateDialog from './ComplateDialog'
  export default {
		name: 'GameCenter',
		components:{PlaceDialog,ComplateDialog},
    data() {
      return {
        map: '',
        xyzTilelayer: '',
        findPlaceDetail: '',
        markers: [],
        nextPlace: '',
				runIcon: require('../assets/run.png'),
				placeDialogDatas: {
					show: false, aniShow: false
				},
				complateDatas: {
          show: false
        },
		scriptDetail: '',
		punchShow: false,
		stepCompleteFlag: false,
		showTips: false,
		totalScore: 0,
		punchCount: 0,  // 已打卡数
		currentPlaceScriptLine: '',
		currentPlaceScriptVoice: '',
		oAudio: '',
		isInit: false,
		currentPos: {
			latitude: '',
			longitude: ''
		}
      }
    }, 
    mounted () {
			this.initMap();
			this.currentPos.latitude  = this.$store.state.projectDatas.latitude;
			this.currentPos.longitude = this.$store.state.projectDatas.longitude;
    },
		beforeRouteEnter(to, from, next) {
			if(from.name != 'ScriptDetail'){
				next(vm=> {
					console.log(vm);
					
					if(vm.$route.query.taskComplateStatus) {
						vm.nextPlace.scriptPlaceResultBo.isCompleteTask = true;
					}
					// // 计算积分 
					vm.totalScore += vm.nextPlace.taskIntegral;
					let allComplate = vm.scriptDetail.stepList.every((cur, index, arr)=> {
						return cur.scriptPlaceResultBo.isCompleteTask == true
					})
					if(allComplate) {
						// 全部答题完毕
						vm.complateDatas = Object.assign(vm.complateDatas, {
							show: true, totalScore: vm.totalScore
						})
					}
					vm.scriptDetail.stepList.forEach((item,index) => {
						if(item.scriptPlaceResultBo.isCompleteTask) {
							vm.markers[index].setContent(`<div class="custom-location-style">
									<div class="location-content">
										<img src="${vm.scriptDetail.stepList[index].scriptPlaceResultBo.placeImages[0]}" mode="aspectFit"></img>
									</div>
								</div>`)
							
						}else {
							vm.markers[index].setContent(`<div class="custom-location-style">
								<div class="location-content">${index+1}</div>
							</div>`)
						}
					})
					
					vm.highlightSpot({lng: vm.nextPlace.scriptPlaceResultBo.placeLongitude,lat: vm.nextPlace.scriptPlaceResultBo.placeLatitude});
					
					
					// // 已打卡数 
					// vm.punchCount++;
					// sessionStorage.setItem("punchCount", vm.punchCount);
					// sessionStorage.setItem("placeCount", scriptPlayResult.scriptRolePlaceList.length);
				});
			}else {
				next()
			}
		},
		beforeRouteLeave(to, from, next){
			if(to.path == '/'){
				if (this.$vnode && this.$vnode.parent) {
					let keepAlive = this.$vnode.parent.parent.componentInstance;
					if (keepAlive && keepAlive.cache && keepAlive.cache[this.$vnode.key]) {
						delete keepAlive.cache[this.$vnode.key];
					}
				}
				this.$destroy()
			}
			next()
		},
    methods: {
			initMap () {
				
				this.initDatas();
				
				this.oAudio = document.getElementById('script_audio');
			},
      initDatas() {
				this.scriptDetail = JSON.parse(sessionStorage.getItem('playCurrentScript'));
				let protocol = window.location.protocol;
				let domain = window.location.host;
				let url = protocol+ '//' + domain;
				
				// 生成地图
				this.map = new AMap.Map('container', {
					resizeEnable: true,
					zoom: 18
				})
				// 瓦片图层实例
				this.xyzTilelayer = new AMap.TileLayer({
					getTileUrl: url + this.scriptDetail.drawingMapPath + '[z]/[x]_[y].png',
					zIndex: 100
				})
				// 添加手绘地图	   
				this.map.add(this.xyzTilelayer);
				
				
				this.map.on('click', (e)=> {
					this.restoreMarkerStyle()
				})
				

        this.nextPlace = this.scriptDetail.stepList[0];
        this.drawLocationIcon(this.scriptDetail.stepList,0);
        this.highlightSpot({lng: this.nextPlace.scriptPlaceResultBo.placeLongitude,lat: this.nextPlace.scriptPlaceResultBo.placeLatitude});
      },
      /**
			 * 绘制群组地点图标
			 * @param {Object} placeDatas  地点数据集合
			 * @param {Number} currentIndex 当前地点索引
			 */
			drawLocationIcon(placeDatas,currentIndex) {
				let that = this;
				// 循环创建点标注
				for(let i=0; i<placeDatas.length; i++ ){
					// 创建点标注实例
					let marker = new AMap.Marker({
						position: new AMap.LngLat(placeDatas[i].scriptPlaceResultBo.placeLongitude, placeDatas[i].scriptPlaceResultBo.placeLatitude),
						content: `<div class="custom-location-style">
									<div class="location-content">${i+1}</div>
								</div>`,
						offset: new AMap.Pixel(-13,-90),
						extData: {
							id: placeDatas[i].scriptPlaceResultBo.placeId,
							image: placeDatas[i].scriptPlaceResultBo.placeImages[0],
							placeName: placeDatas[i].scriptPlaceResultBo.placeName,
							data: placeDatas[i],
							videoUrl: placeDatas[i].scriptPlaceResultBo.placeVideoUrl,
							index: i
						}
					})
					// // 只对游玩过的地点进行状态处理
					// if(i < currentIndex) {
					// 	// 游客完成打卡和任务(显示正确)
					// 	if(placeDatas[i].completeFlag) {
					// 		marker.setContent(`<div class="custom-location-style--img">
					// 			<div class="location-content" style="background: #fff;">
					// 				<image src="../../static/images/correct.png" mode="aspectFit"></image>
					// 			</div>
					// 		</div>`)
					// 	}else {
					// 		// 游客跳过打卡和任务(显示错误)
					// 		marker.setContent(`<div class="custom-location-style--img">
					// 			<div class="location-content" style="background: #fff;">
					// 				<image src="../../static/images/miss.png" mode="aspectFit"></image>
					// 			</div>
					// 		</div>`)
					// 	}
					// }
					
					// 为点标注设置标题
					marker.setLabel({
						direction:'bottom',
						offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
						content: `<div class='custom-location-name'>${placeDatas[i].scriptPlaceResultBo.placeName}</div>`, //设置文本标注内容
					})
					
					// 给地点标注绑定点击事件
					marker.on('click',function(e){
						that.placeDialogDatas = Object.assign(that.placeDialogDatas , {
							show: false, aniShow: true, placeDatas: that.findPlaceDetail
						})
						// 拿到被点击地点的详情数据
						that.findPlaceDetail = e.target._originOpts.extData.data;

						that.placeDialogDatas = Object.assign(that.placeDialogDatas , {
							show: true, aniShow: true, placeDatas: that.findPlaceDetail
						})
						// 重置所有点标注样式
						that.restoreMarkerStyle(that.findPlaceDetail.index-1)
					
						// 设置被点击点为中心点
						that.map.setCenter([e.lnglat.lng, e.lnglat.lat],false,220);

						// 将当前被点击的点标注改为地点图片展示
						marker.setContent(`<div class="custom-location-checked-style--img animate__animated animate__heartBeat">
								<div class="location-content-checked">
									<img src="${that.findPlaceDetail.scriptPlaceResultBo.placeImages[0]}" mode="aspectFit"></img>
								</div>
							</div>`)
						
					
	
					})
					this.markers.push(marker)
				}
				// 创建覆盖物群组,并添加至地图上
				let overlayGroups = new AMap.OverlayGroup(this.markers);
				this.map.add(overlayGroups)
			
			},
			/**
			 * 重置所有的点标注样式
			 * @param {Object} currentIndex 当前地点索引
			 */
			 restoreMarkerStyle(currentIndex) {
				// 高亮地点必须在绘制地图之后
				if(!this.stepCompleteFlag) {
					this.markers.forEach((item,i)=> {
						if(this.scriptDetail.stepList[i].scriptPlaceResultBo.isCompleteTask) {
							item.setContent(`<div class="custom-location-style">
									<div class="location-content">
										<image src="${this.scriptDetail.stepList[i].scriptPlaceResultBo.placeImages[0]}" mode="aspectFit"></image>
									</div>
								</div>`)
						}else {
							item.setContent(`<div class="custom-location-style">
								<div class="location-content">${i+1}</div>
							</div>`)
						}
					})
					// 高亮当前要去的地点
					this.highlightSpot({lng: this.nextPlace.scriptPlaceResultBo.placeLongitude,lat: this.nextPlace.scriptPlaceResultBo.placeLatitude});
				}
			},
      /**
			 * 高亮地点
			 * @param {Object} currentIndex 需要高亮地点的坐标
			 */
			highlightSpot({lng, lat}) {
				// 高亮下一个地点
				this.markers[this.nextPlace.stepNo-1].setContent(`<div class="custom-location-high-style">
					<div class="location-content-high--img">
						<div class="location-circle circle01"></div>
						<div class="location-circle circle02"></div>
						<div class="location-circle circle03"></div>
					</div>
				</div>`)   
				// 设置高亮点为地图中心点
				this.map.setCenter([lng, lat],false,220)
			},
			// 去到当前指定的地点
			toCurrentPlace() {
				this.nextPlace = this.findPlaceDetail;
				this.placeDialogDatas.show = false;
				this.restoreMarkerStyle()
			},
			// 打开打卡面板
			openPunch() {
				this.placeDialogDatas = Object.assign(this.placeDialogDatas , {
					show: false, aniShow: true, placeDatas: this.findPlaceDetail
				})
				
				this.currentPlaceScriptLine = this.nextPlace.beforeStoryContent;
				this.currentPlaceScriptVoice = this.nextPlace.beforeStoryVoiceUrl;
				this.punchShow = true;
				setTimeout(() => {
					this.oAudio.play()
				}, 300);
			},
			toAnswerPage() {
				if(this.nextPlace.questionResult.playType == 3) {
					this.$router.push({path: '/estAnswer',query: {stepNo: this.nextPlace.stepNo, scriptIndex: this.$route.query.scriptIndex}})
				}else if(this.nextPlace.questionResult.playType == 1) {
					this.$router.push({path: '/choAnswer', query: {stepNo: this.nextPlace.stepNo, scriptIndex: this.$route.query.scriptIndex}})
				}else if(this.nextPlace.questionResult.playType == 5) {
					this.$router.push({path: '/wordAnswer', query: {stepNo: this.nextPlace.stepNo, scriptIndex: this.$route.query.scriptIndex}})
				}
				this.punchShow = false;
			},
			toHome() {
				this.$router.push({path:'/'})
			},
			// 打卡
			onPunch() {
				this.nextPlace.scriptPlaceResultBo.isCompletePunch = true;
				this.nextPlace.scriptPlaceResultBo.isCompleteTask = true;
				this.showTips = true;
				setTimeout(() => {
					this.showTips = false;
				}, 3000);
			},
			onLocation() {
				let marker = new AMap.Marker({
					position: new AMap.LngLat(this.currentPos.longitude, this.currentPos.latitude),
					content: `<div class="custom-location-high-style">
					<div class="location-content-high--img"></div>
				</div>`,
					offset: new AMap.Pixel(-13,-90)
				})
				this.map.add(marker)
				this.map.setCenter(new AMap.LngLat(this.currentPos.longitude, this.currentPos.latitude))
			}
    }
  }
</script>

<style lang="less" scoped>
  .game-center {
    #container {
		  width: 100vw;
		  height: 100vh;
		}
		.home-btn {
			position: fixed;
			top: 30px;
			left: 50px;
		}
		.seal {
			position: fixed;
			top: 30px;
			right: 50px;
		}
		.operation-bar {
			position: fixed;
			bottom: 30px;
			right: 50px;
		}
		.script-name {
			position: fixed;
			bottom: 30px;
			left: 50px;
			display: flex;
			align-items: flex-end;
			.name {
				position: relative;
				width: 440px;
				height: 200px;
				margin-left: -60px;
				z-index: -9;
				p {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					margin-left: 40px;
					text-align: center;
					line-height: 192px;
					font-size: 42px;
					text-shadow: 0 0 10px rgba(155, 58, 2, 0.767);

				}
			}
		}
		.punch-dialog {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.punch-container {
				position: relative;
				width: 620px;
				height: 300px;
				background: rgba(23, 34, 33,.9);
				border: 6px solid #B3D7FF;
				border-radius: 16px;
				.title {
					padding: 20px 0 0;
					font-size: 30px;
					text-align: center;
				}
				.content {
					padding: 50px 30px 0;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					.poster {
						display: flex;
						align-items: center;
						img {
							border-radius: 16px;
							border: 4px solid #657D90;
						}
					}
					.task {
						margin-left: 24px;
						font-size: 22px;
						p {
							margin-bottom: 12px;
						}
					}
					.btn {
						margin-top: -20px;
						.item {
							width: 220px;
						}
						img {
							width: 100%;
						}
					}
				}
			}
			.close-btn {
				position: absolute;
				top: 12px;
				right: 12px;
			}
		}
		.storyline-dialog {
			position: absolute;
      bottom: 20px;
      left: 60px;
      width: 492px;
      height: 847px;
      padding: 40px 40px 0;
      box-sizing: border-box;
      background: url('../assets/script-detail-bg.png')no-repeat;
      background-size: 100%;
      border-radius: 20px;
      overflow: hidden;
			.title {
        text-align: center;
        font-size: 26px;
      }
			.sub-title {
				padding: 12px 0;
				font-size: 22px;
			}
			.scroll-wrap::-webkit-scrollbar {
        display: none;
      }
			.content {
				font-size: 20px;
        line-height: 34px;
        text-indent: 2em;
			}
		}
		.ansTips {
			padding: 10px 24px;
			position: fixed;
			left: 42%;
			bottom: 100px;
			z-index: 999;
			font-size: 22px;
			border-radius: 6px;
			background: rgba(0,0,0,.7);
		}
  }
</style>