<template>
  <div class="container">
    
  </div>
</template>

<script>
  export default {
    data () {
      return {
        
      }
    },
    mounted () {
      let deviceCode = window.location.search.split('=')[1] || 'YT9V5WHX';

      // 检查本地存储，当本地有deviceCode时就不再获取
      if(!localStorage.getItem('deviceCode')) {
        localStorage.setItem('deviceCode', deviceCode)
      }else {
        deviceCode = localStorage.getItem('deviceCode')
      }
      // 请求数据
      this.$http.get('/web/terminal/script/getTerminalJSONData',{
        params: {
          deviceCode: deviceCode
        }
      }).then(res=> {
        let resObj = JSON.parse(res.data.info)
        this.$store.commit('updateProjectDatas', resObj)
        setTimeout(() => {
          this.$router.push({
            path: '/home'
          })
        }, 500);
      })
 
    },
    methods: {
      
      
    },
    beforeRouteEnter(to, from, next) {
      if(from.path == '/game-center') {
        next()
        window.location.reload()
        
      }else {
        next()
      }
      
    }
  }
</script>

<style lang="scss" scoped>

</style>