<template>
  <div v-if="config.show" class="complateDialog">
    <div class="complate-container animate__animated animate__faster" :class="[config.show ? 'animate__zoomIn': 'animate__zoomOut']">
      <div class="status">
        <img src="../assets/cleared.png" alt="" style="width: 340px;height: 300px;margin-top: -80px;">
      </div>
      <p class="title">通关成功! 本次获得{{ config.totalScore }}积分，真是太棒啦~</p>
      <div class="btn">
        <img src="../assets/back-home-btn.png" @click="toHome" alt="">
      </div>
      <!-- 关闭按钮 -->
      <div class="close-btn" @click="config.show = false">
        <img src="../assets/close-btn-w.png" alt="">
      </div>
		</div>
  </div>
</template>

<script>
  export default {
    props: {
      config: ''
    },
    data () {
      return {
        
      }
    },
    methods: {
      toHome() {
        sessionStorage.setItem('placeCount',0)
        sessionStorage.setItem('punchCount',0)
        sessionStorage.setItem('taskIntegral',0)
        this.$router.push({path:'/'})
      }
    }
  }
</script>

<style lang="less" scoped>
  .complateDialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .complate-container {
      margin-top: 100px;
      padding: 0px 30px;
      position: relative;
      width: 880px;
      height: 460px;
      background: #172221;
      box-sizing: border-box;
      border: 6px solid #B3D7FF;
      border-radius: 16px;
      text-align: center;
      .title {
        margin-top: -80px;
        font-size: 26px;
      }
      .status {
        margin: 50px 0;
      }
      .btn {
        margin-top: 20px;
      }
    }
    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
</style>