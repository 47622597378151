<template>
  <div class="home">
    <!-- 头部 -->
    <div class="header" style="height:auto;">
      <router-link to="/" class="home-btn" style="top:0;left:0;">
        <img :src="projectDatas.titleImgUrl" alt="">
      </router-link>
    </div>
    <!-- 主体 -->
    <div class="main">
      <div class="left-panel">
        <div class="intro-wrap">
          <img class="animate__animated animate__slow animate__flipInY animate__delay-05s" :src="projectDatas.ipUrl" alt="" style="margin-top: -30px;">
          <div class="intro-content animate__animated animate__slow animate__fadeIn animate__delay-1s">
            永春数智定向游结合了永春历史人文故事、乡村资源，打造个性化实景定向游沉浸式体验，一部手机听讲解、导览乡村全貌，通过实景定向打卡、任务闯关、趣味问答等创新游玩模式，吸引游客在定向游互动任务中，了解永春、深入永春、感受永春的特色历史文化魅力。
          </div>
        </div>
        <!-- 剧本 -->
        <div class="script-wrap animate__animated animate__fast animate__rotateInDownLeft">
          <img src="../assets/script-exp-title.png" alt="">
          <div v-if="projectDatas.scriptList.length !== 1" class="script-list">
            <div class="script-item" v-for="(item,index) of projectDatas.scriptList" :key="index" @click="toScriptDetailPage(index)">
              <div class="script-poster">
                <img :src="item.coverPosterUrl" alt="">
              </div>
              <p>{{ item.scriptName }}</p>
            </div> 
          </div>
          <!-- 单个剧本情况 -->
          <div v-if="projectDatas.scriptList.length === 1" class="script-list" @click="toScriptDetailPage(0)">
            <div class="script-item">
              <div class="script-poster">
                <img :src="projectDatas.scriptList[0].coverPosterUrl" alt="">
              </div>
              <p>{{ projectDatas.scriptList[0].scriptName }}</p>
            </div>
            <div class="script-description">
              {{ projectDatas.scriptList[0].simpleIntroduce }}
            </div>
          </div>

        </div>
      </div>
      <div class="right-panel animate__animated animate__fast animate__rotateInDownRight">
        <div class="tabs">
          <div class="slide-block" :class="[currentTabIndex == 0? 'tab01':'tab02']"></div>
          <div class="place-map" @click="checkTab(0)">
            <img src="../assets/play-map.png" alt="">
          </div>
          <div class="play-mode" @click="checkTab(1)">
            <img src="../assets/play-mode.png" alt="">
          </div>
        </div>
        <div class="show-panel">
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="map-panel animate__animated animate__animated">
                  <img :src="projectDatas.placeOverviewUrl" alt="">
                  <div class="scale-btn" @click="onScale">
                    <img src="../assets/scale-icon.png" alt="">
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="mode-panel">
                  <div class="mode-tabs">
                    <div class="mode-tab-item" :class="{active: currentModeIndex == 1}" @click="selectMode(1)">
                      <img src="../assets/mode-tab01.png" alt="">
                    </div>
                    <div class="mode-tab-item" :class="{active: currentModeIndex == 2}" @click="selectMode(2)">
                      <img src="../assets/mode-tab02.png" alt="">
                    </div>
                    <div class="mode-tab-item" :class="{active: currentModeIndex == 3}" @click="selectMode(3)">
                      <img src="../assets/mode-tab03.png" alt="">
                    </div>
                  </div>
                  <div class="phone-preview">
                    <div class="phone-wrap">
                      <div class="video-wrap">
                        <video :src="modeVideo" controls="false" loop="true" autoplay="true" style="pointer-events: none" ></video>
                      </div>
                    </div>
                    <div class="discription-wrap">
                      <div class="qr-wrap">
                        <img :src="projectDatas.appletQrCodeUrl" alt="" style="width: 100px;margin-right: 12px;border-radius: 10px;border: 2px solid #fff;padding: 6px;">
                      </div>
                      <div class="discription">
                        玩家可以选择游玩不同的剧本，通过完成各种任务和挑战来获得奖励点数并进一步解锁更多的游乐设施和道具
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 地图放大 -->
    <MapScaleDialog :datas="mapScalceDialogDatas"></MapScaleDialog>
  </div>
</template>

<script>
  import LightBeam from '../components/lightBeam'
  import MapScaleDialog from './MapScaleDialog';
  import Swiper from 'swiper';
  export default {
    name: 'Home',
    components: {MapScaleDialog,LightBeam},
    data() {
      return {
        currentTabIndex: 0,
        currentModeIndex: 1,
        swiper: '',
        mapScalceDialogDatas: {
          show: false
        },
        projectDatas: this.$store.state.projectDatas,
        modeVideo: ''
      }
    },
    created () {
      console.log(this.projectDatas)
      // $(document).ready(function () {
      //   var stars = 100;
      //   var $stars = $('.stars');
      //   var r = 400;
      //   for (var i = 0; i < stars; i++) {
      //       if (window.CP.shouldStopExecution(1)) {
      //           break;
      //       }
      //       var $star = $('<div/>').addClass('star');
      //       $stars.append($star);
      //   }
      //   window.CP.exitedLoop(1);
      //   $('.star').each(function () {
      //       var cur = $(this);
      //       var s = 0.2 + Math.random() * 1;
      //       var curR = r + Math.random() * 300;
      //       cur.css({
      //           transformOrigin: '0 0 ' + curR + 'px',
      //           transform: ' translate3d(0,0,-' + curR + 'px) rotateY(' + Math.random() * 360 + 'deg) rotateX(' + Math.random() * -50 + 'deg) scale(' + s + ',' + s + ')'
      //       });
      //   });
      // });

    },
    mounted () {
      this.swiper = null;
      this.swiper = new Swiper(".mySwiper", {
        initialSlide: this.currentTabIndex,
        grabCursor: true
      })
      this.swiper.on('slideChange',(params)=>{
        this.currentTabIndex = params.activeIndex;
      })
      // 默认先播放游园模式的视频
      this.modeVideo = this.projectDatas.galaPartyModeVideoUrl;
    },
    methods: {

      toScriptDetailPage(scriptIndex) {
        this.$router.push({
          path:'/script-detail',
          query:{
            scriptIndex: scriptIndex
          }
        })
      },
      checkTab(index){
        this.currentTabIndex = index;
        this.swiper.slideTo(index)
      },
      selectMode(index){
        this.currentModeIndex = index;
        if(index == 1) {
          this.modeVideo = this.projectDatas.galaPartyModeVideoUrl;
        }else if(index == 2) {
          this.modeVideo = this.projectDatas.personModeVideoUrl;
        }else if(index == 3) {
          this.modeVideo = this.projectDatas.teamModeVideoUrl;
        }
      },
      onScale() {
        this.mapScalceDialogDatas = Object.assign(this.mapScalceDialogDatas, {
          show: true, scale: true, mapUrl: this.projectDatas.placeOverviewUrl
        })
      },
    },
    beforeDestroy () {
      this.swiper.destroy()
    },

   
  }
</script>

<style lang="less" scoped>
  .home {
    .main {
      padding: 80px 50px 0;
      display: flex;
      justify-content: space-between;
      .left-panel {
        width: 44%;
        height: 800px;
        .intro-wrap {
          display: flex;
          justify-content: space-between;
          height: 280px;
          .intro-content {
            width: 579px;
            height: 222px;
            padding: 20px 30px 30px 42px;
            box-sizing: border-box;
            background: url('../assets/intro-border-bg.png')no-repeat left center;
            background-size: 100%;
            text-indent: 2em;
            font-size: 20px;
            text-align: left;
            line-height: 30px;
            letter-spacing: 2px;
          }
        }
        .script-wrap {
          margin-top: 30px;
          padding: 20px 30px 38px;
          background: rgba(42, 50, 67, 0.8);
          border-radius: 20px;
          .script-list {
            padding: 26px 26px 34px;
            display: flex;
           
            // justify-content: space-between;
            background: rgba(63,72,88,.8);
            border-radius: 20px;
            .script-item {
              margin-left: 18px;
              width: 30%;
              text-align: center;
              .script-poster {
                height: 276px;
                border-radius: 16px;
                object-fit: fill;
                overflow: hidden;
              }
              p {
                margin-top: 16px;
                font-size: 18px;
              }
            }
            img {
              width: 100%;
              height: 100%;
            }
            .script-description {
              flex: 1;
              height: 276px;
              margin-left: 24px;
              font-size: 18px;
              line-height: 30px;
              overflow-y: scroll;
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
      .right-panel {
        padding: 30px 50px 0;
        width: 54.5%;
        height: 800px;
        background: rgba(42, 50, 67, 0.8);
        border-radius: 20px;
        box-sizing: border-box;
        .tabs {
          position: relative;
          display: flex;
          margin-bottom: 32px;
          .slide-block {
            position: absolute;
            width: 300px;
            height: 90px;
            border-radius: 20px;
            background: #4c5464;
            z-index: -1;
            transition: all 330ms cubic-bezier(0.16, -0.29, 0.66, 1.02);
          }
          .tab01 {
            left: 0;
            
          }
          .tab02 {
            left: 320px;
            
          }
          .place-map {
            padding: 6px 30px;
            margin-right: 20px;
          }
          .play-mode {
            padding: 6px 30px;
          }
        }
        .show-panel {
          height: 610px;
          border-radius: 20px;
          background: rgba(63,72,88,.6);
          overflow: hidden;
          .swiper-slide {
            height: 610px;
          }
          .map-panel {
            height: 100%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
             
            }
            .scale-btn {
              position: absolute;
              bottom: 28px;
              right: 40px;
            }

          }
          .mode-panel {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 26px 26px;
            box-sizing: border-box;
            .mode-tabs {
              .mode-tab-item {
                padding: 0 12px 12px;
                margin-bottom: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                height: 110px;
                background: #1D2635;
                border-radius: 16px;
                border: 2px solid transparent;
                transition: all 330ms linear;
              }
              .active {
                border: 2px solid #9EB8EE;
                box-shadow: 4px 4px 10px 1px #9EB8EE inset;
                animation: op 330ms cubic-bezier(0.68, 1.22, 1, 1.25) forwards;
              }
            }
            @keyframes op {
              0% {
                transform: scale(1.3,.5);
              }
              100% {
                transform: scale(1);
              }
            }
            .phone-preview {
              margin-left: 100px;
              flex: 1;
              display: flex;
              align-items: center;
              .phone-wrap {
                width: 251px;
                height: 509px;
                background: url(../assets/phone-bg.png)no-repeat;
                background-size: 100%;
                .video-wrap {
                  position: relative;
                  top: 10px;
                  width: 254px;
                  height: 490px;
                  border-radius: 50px;
                  overflow: hidden;
                  video {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              .qr-wrap {
                margin-left: 32px;
                text-align: center;
                margin-bottom: 30px;
              }
              .discription {
                margin-left: 32px;
                padding: 26px;
                width: 200px;
                background: #424B57;
                border-radius: 16px;
                font-size: 20px;
                line-height: 34px;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }
    .copyright {
      padding: 18px 60px;
      display: flex;
      align-items: center;
      p {
        padding: 4px 0;
      }
    }
  }
</style>