<template>
  <div id="app">
		<div class="stars"></div>
    <!-- <light-beam></light-beam> -->
		<transition enter-active-class="animate__animated animate__fadeIn">
			<keep-alive :include="['Home', 'GameCenter']">
				<router-view/>
			</keep-alive>
			
		</transition>
    
  </div>
</template>

<script>

import "./libs/prefixfree.min"
import "./libs/stopExecutionOnTimeout"

export default {
  data() {
    return {
      
    }
  },

  mounted() {
		
    let url = 'https://webapi.amap.com/maps?v=2.0&key=6595606a87acc31ff63e8b0415d06b2d&plugin=AMap.Walking,AMap.MoveAnimation,AMap.Geolocation,AMap.ControlBar'
    let jsapi = document.createElement('script')
    jsapi.src = url;
    document.head.appendChild(jsapi)
  },
	

}
</script>
<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		user-select: none;
  }
	@keyframes rotate {
	  0% {
	    transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0);
	  }
	  100% {
	    transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(-360deg);
	  }
	}
	.stars {
	  transform: perspective(500px);
	  transform-style: preserve-3d;
	  position: absolute;
	  bottom: 0;
	  perspective-origin: 50% 100%;
	  left: 50%;
	  animation: rotate 90s infinite linear;
	}

	.star {
	  width: 2px;
	  height: 2px;
	  background: #F7F7B6;
	  position: absolute;
	  top: 0;
	  left: 0;
	  transform-origin: 0 0 -300px;
	  transform: translate3d(0, 0, -300px);
	  backface-visibility: hidden;
	}
  // 去掉高德logo水印 
	.amap-logo, .amap-copyright{
		display: none!important;
		opacity: 0!important;
	}
	/* 点标注样式 */
	.custom-location-style {
		position: relative;
		width: 56px;
		height: 90px;
		box-sizing: 99999;
		background: url('./assets/location.png')no-repeat center;
		background-size: 100%;
		display: flex;
		justify-content: center;
	}
	// 高亮样式
	.custom-location-high-style {
		position: relative;
		width: 56px;
		height: 90px;
		box-sizing: 99999;
		background: url('./assets/high-place.png')no-repeat center;
		background-size: 100%;
		display: flex;
		justify-content: center;
	}
	.custom-location-style--img {
		position: relative;
		width: 66px;
		height: 76px;
		background: url('./assets/location.png')no-repeat center;
		background-size: 100%;
		display: flex;
		justify-content: center;
		transition: scale(1.5);
	}
	.custom-location-checked-style--img {
		position: relative;
		width: 66px;
		height: 88px;
		background: url('./assets/select-place.png')no-repeat center;
		background-size: 100%;
		display: flex;
		justify-content: center;
		transition: scale(1.4);
	}
	.location-content {
		margin-top: 8px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		background: #fff;
		border-radius: 50%;
		text-align: center;
		color: #333;
		font-size: 26px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.location-content-checked {
		margin-top: 9px;
		width: 46px;
		height: 46px;
		line-height: 46px;
		background: #fff;
		border-radius: 50%;
		text-align: center;
		color: #333;
		font-size: 26px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.custom-location-name {
		position: relative;
		margin:0;
		top: 0;
		left: 0;
		right: 0;
		min-width: 0;
		z-index: 9;
	}
	.amap-marker-label {
		transform: translate(-7%,6px);
		padding: 8px 22px;
		background: #fff;
		border-radius: 60px;
		color: #757575;
		font-size: 20px;
	}
	.location-content--img {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 8px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		background: #ff910a;
		border-radius: 50%;
		text-align: center;
		color: #fff;
		font-size: 30px;
		// overflow: hidden;
		img {
			width: 60%;
			height: 60%;
			animation: move 1.2s linear infinite alternate;
		}
	}
	.location-content-high--img {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 8px;
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
	.location-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 48px;
		height: 48px;
		z-index: -2;
		border: 2px solid #01beff;
		box-shadow: 0 0 16px 2px #00a6dd inset;
		border-radius: 50%;
		transform: translate(-50%,-50%);
		animation: diff 4s infinite;
	}
		
	.location-content--img .circle02 {
		animation-delay: 1s;
	}
	.location-content--img .circle03 {
		animation-delay: 2s;
	}
  @keyframes diff {
		0% {
			opacity: .8; 
			width: 120%;
			height: 120%;
			top: 50%;
			left: 50%;
		}
		100% {
			opacity: 0;
			width: 300%;
			height: 300%;
			top: 50%;
			left: 50%;
		}
	}
</style>
