import Vue from 'vue'
import VueRouter from 'vue-router'
import Loading from '../views/loading.vue'
import Home from '../views/Home.vue'
import ScriptDetail from '../views/ScriptDetail.vue'
import GameCenter from '../views/GameCenter.vue'
import EstAnswer from '../views/EstAnswer.vue'
import ChoAnswer from '../views/ChoAnswer.vue'
import WordAnswer from '../views/WordAnswer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Loading',
    component: Loading,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/script-detail',
    name: 'ScriptDetail',
    component: ScriptDetail
  },
  {
    path: '/game-center',
    name: 'GameCenter',
    component: GameCenter
  },
  {
    path: '/estAnswer',
    name: 'EstAnswer',
    component: EstAnswer
  },
  {
    path: '/choAnswer',
    name: 'ChoAnswer',
    component: ChoAnswer
  },
  {
    path: '/wordAnswer',
    name: 'WordAnswer',
    component: WordAnswer
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
