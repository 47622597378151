<template>
  <div v-show="datas.show" class="map-dialog">
    <div class="container animate__animated animate__faster" :class="[datas.scale? 'animate__fadeIn': 'animate__fadeOut']">
      <img class="home-map" :src="datas.mapUrl" alt="">
      <!-- 关闭按钮 -->
      <div class="close-btn" @click="datas.show = false">
        <img src="../assets/close.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      datas: {
        show: false,
        scale: false,
        mapUrl: ''
      }
    },
    data () {
      return {
        
      }
    },
    mounted () {
      
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  .map-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      position: relative;
      width: 68%;
      height:900px;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .home-map {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
</style>