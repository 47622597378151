<template>
  <div class="script-detail">
    <!-- 头部 -->
    <div class="header">
      <img class="header-bg" :src="projectDatas.titleImgUrl" alt="">
      <router-link to="/" class="home-btn">
        <img src="../assets/home-btn.png" alt="">
      </router-link>
      <div @click="backPage" class="home-back">
        <img src="../assets/back-btn.png" alt="">
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="main">
      <!-- 剧本面板 -->
      <div class="script-panel animate__animated animate__fast animate__zoomIn">
        <!-- 剧情 -->
        <div class="script-content">
          <div class="poster-wrap">
            <div class="mode-type">{{scriptDetail.scriptModeName}}</div>
            <img :src="scriptDetail.coverPosterUrl" alt="">
          </div>
          <div class="content">
            {{ scriptDetail.simpleIntroduce }}
          </div>
        </div>
        <!-- 操作条 -->
        <div class="operation-bar">
          <div class="script-name">{{ scriptDetail.scriptName }}</div>
          <div class="operation-btn">
            <img class="map-btn" src="../assets/map-btn.png" alt="" @click="openMapDialog">
            <img class="play-btn" src="../assets/play-btn.png" alt="" @click="toGemeCenterPage">
          </div>
        </div>
        <!-- 剧本数据 -->
        <div class="script-datas">
          <div class="script-des">
            <div class="label-wrap">
              <div class="label">剧本特色</div>
              <div>{{ scriptDetail.scriptLabelNames }}</div>
            </div>
            <div class="label-wrap" style="margin: 0 90px;">
              <div class="label">游戏时间</div>
              <div>{{ scriptDetail.limitTime }} 分钟</div>
            </div>
            <div class="label-wrap">
              <div class="label">游玩人数</div>
              <div>{{ scriptDetail.recommendNumber }} 人</div>
            </div>
          </div>    
          <div class="place-list">
            <div class="swiper-container swiper-place">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in scriptDetail.placeImageList" @click="onPreview(item,index)">
                      <img :src="item" alt="">
                    </div>
                </div>
            </div>
          </div>                                                                                                                            </div>
        </div>
      <!-- 角色面板 -->
      <div class="role-panel animate__animated animate__fast animate__flipInY">
        <div class="role-wrap">
          <div class="swiper-container swiper-role">
              <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item,index) of scriptDetail.scriptRoleList" :key="index">
                    <div class="role">
                      <div class="role-ip">
                        <img :src="item.scriptRolePosterUrl" alt="">
                      </div>
                      <div class="role-intro">
                        <p class="name">{{ item.scriptRoleName }}</p>
                        <div class="intro">{{ item.scriptRoleIntroduce}}</div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="role-list">
          <div class="list-wrapper">
            <div class="role-item" :class="{active: currentSelRoleIndex == index}"
              v-for="(item,index) of scriptDetail.scriptRoleList"
              @click="onSelectRole(index)"> 
              <img :src="item.scriptRolePosterUrl" alt="">
            </div> 
          </div>
        </div>
      </div>
    </div>
    <MapDialog :config="mapDialogDatas"></MapDialog>
    <SlidePreview :datas="slidePreviewDatas" @close="slidePreviewDatas.show = false"></SlidePreview>
  </div>
</template>

<script>
  import Swiper from 'swiper';
  import MapDialog from './MapDialog';
  import SlidePreview from '../components/slidePreview.vue';
  export default {
    components: {MapDialog,SlidePreview},
    data() {
      return {
        mapDialogDatas: {
          show: false
        },
        slidePreviewDatas: {
          show: false
        },
        currentSelRoleIndex: 0,
        swiper: '',
        placeSwiper: '',
        scriptDetail: '',
        projectDatas: this.$store.state.projectDatas,
      }
    },
    mounted () {
     
      this.scriptDetail = this.$store.state.projectDatas.scriptList[this.$route.query.scriptIndex];
      this.scriptDetail.scriptLabelNames = this.scriptDetail.scriptLabelNames.join(',')
      this.initSwiper()
    },
    updated () {
      this.initSwiper()
    },
    methods: {
      initSwiper() {
        this.placeSwiper = new Swiper('.swiper-place',{
          slidesPerView: 5,
          spaceBetween: 30,
          grabCursor: true,
          freeMode: true,
        })
        this.swiper = new Swiper('.swiper-role',{
            initialSlide: this.currentSelRoleIndex,
            grabCursor: true
        })
        this.swiper.on('slideChange',(params)=>{
            this.currentSelRoleIndex = params.activeIndex;
        })
      },
      backPage() {
        this.$router.go(-1)
      },
      toGemeCenterPage() {
        sessionStorage.setItem('playCurrentScript', JSON.stringify(this.scriptDetail))
        this.$router.push({path: '/game-center',query: {scriptIndex: this.$route.query.scriptIndex}})
      },
      openMapDialog() {
        this.mapDialogDatas = Object.assign(this.mapDialogDatas, {
          show: true, scale: true, url: this.scriptDetail.routeImageUrl
        })
      },
      onPreview(item,index){
        let imgArr = [];
        this.slidePreviewDatas = {show: false}
        for(let i=0; i<this.scriptDetail.placeImageList.length;i++){
          imgArr.push(this.scriptDetail.placeImageList[i])
        }
        this.slidePreviewDatas = Object.assign(this.slidePreviewDatas,{
          show: true,imgsList: imgArr,index: index
        })
      },
      onSelectRole(index) {
        this.currentSelRoleIndex = index;
        this.swiper.slideTo(index)
      }
    }
    
  }
</script>

<style lang="less" scoped>
    .swiper {
      width: 100%;
      height: 100%;
    }

    
  .script-detail {
    .main {
      display: flex;
      justify-content: space-between;
      padding: 12px 50px 0;
    }
    .script-panel {
      padding: 100px 50px 0;
      width: 1296px;
      height: 887px;
      box-sizing: border-box;
      background: url(../assets/script-panel.png)no-repeat;
      .script-content {
        display: flex;
        .poster-wrap {
          position: relative;
          width: 200px;
          .mode-type {
            position: absolute;
            top: -44px;
            padding: 4px 12px;
            background: linear-gradient(180deg, #FFFBF0 5%, #FFDA84 100%);
            color: #B05500;
            border-radius: 8px;
            font-weight: 600;
            letter-spacing: 1px;
            text-shadow: 1px 1px 2px #fff, -1px -1px 2px #fff,1px -1px 2px #fff,-1px 1px 2px #fff,;

          }

          img {
            width: 100%;
            border-radius: 16px;
            overflow: hidden;
          }
        }
        .content {
          flex: 1;
          margin-left: 54px;
          font-size: 28px;
          text-align: left;
          letter-spacing: 4px;
          line-height: 50px;
          text-indent: 2em;
        }
      }
      .operation-bar {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        
        .script-name {
          padding: 0 12px;
          width: 240px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 12px;
          background: rgba(66, 81, 98,.7);
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 4px;
        }
        .operation-btn {
          display: flex;
          align-items: center;
          .map-btn {
            margin-top: -66px;
          }
          .play-btn {
            margin-top: -46px;
            width: 250px;
          }
        }
      }
      .script-datas {
        .script-des {
          margin-top: 60px;
          display: flex;
          .label-wrap {
            display: flex;
            align-items: center;
            font-size: 22px;
            .label {
              margin-right: 16px;
              padding: 10px 20px;
              background: #B3D7FF;
              color: #333;
              border-radius: 16px;
            }
          }
        }
        .place-list {
          padding: 20px 30px;
          margin-top: 50px;
          width: 100%;
          height: 180px;
          box-sizing: border-box;
          border-radius: 20px;
          background: rgba(15,22,27,.6);
          overflow: hidden;
          
          .swiper-place .swiper-slide {
            margin-right: 24px;
            height: 140px;
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            overflow: hidden;
          }
          .swiper-slide img {
            display: block;
            width: 280px;
            height: 140px;
            object-fit: cover;
          }
        }
      }
    }
    .role-panel {
      padding: 110px 50px 0;
      width: 496px;
      height: 887px;
      box-sizing: border-box;
      background: url(../assets/role-panel.png)no-repeat;
      .role-wrap {
        width: 390px;
        overflow: hidden;
      }
      .role {
        background: rgba(52, 67, 84, 0.7);
        border-radius: 18px;
        padding: 30px 30px;
        .role-ip {
          height: 320px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 110%;
            height: 100%;
          }
        }
        .role-intro {
          margin-top: 40px;
          .name {
            text-align: center;
            color: #CDF1AA;
            font-size: 20px;
            font-weight: 600;
          }
          .intro {
            text-align: left;
            margin-top: 16px;
            letter-spacing: 2px;
          }
        }
      }
      .role-list {
        margin-top: 40px;
        display: flex;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .list-wrapper {
          display: flex;
        }
        .role-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 10px;
          margin-right: 12px;
          width: 80px;
          height: 120px;
          border: 2px solid #a3a3a3;
          border-radius: 12px;
          background: rgba(52, 67, 84, 0.7);
          box-sizing: border-box;
          transition: all 220ms ease-in;
          overflow: hidden;
          img {
            width: 140%;
            height: 100%;
          }
        }
        .active {
          border-color: #CDF1AA;
          box-shadow: 0 0 10px 1px #CDF1AA inset;
        }
      }
    }
  }
</style>