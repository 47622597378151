<template>
  <div v-show="config.show" class="map-dialog">
    <div class="container animate__animated animate__faster" :class="[config.scale? 'animate__zoomIn': 'animate__zoomOut']">
      <img class="home-map" :src="config.url" alt="">
      <!-- 关闭按钮 -->
      <div class="close-btn" @click="config.show = false">
        <img src="../assets/close.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      config: {
        show: false,
        scale: false,
        url: ''
      }
    },
    data () {
      return {
        
      }
    },
    mounted () {
      
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  .map-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      position: relative;
      width: 66%;
      height: 780px;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        background: rgba(255,255,255,.4);
        border-radius: 50%;
        display: flex;
        justify-content: center;
      }
      .home-map {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
</style>