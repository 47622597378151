<template>
  <div class="answer">
    <div class="header"></div>
		<div class="main">
      <div class="wrapper">
        <div class="answer-tip">
          {{taskStory}}
        </div>
        <div class="score-wrap">
          <div style="display: flex;align-items: center;font-size: 24px;">
            <div class="mark">场景小考</div>
            <span v-if="question.playType == 1">(单选题)</span>
            <span v-if="question.playType == 5">(选字填空)</span>
            <span v-if="question.playType == 3">(判断题)</span>  
          </div>
          <div class="score">
            <img src="../assets/score.png" alt="" style="margin-right: 10px;">{{taskIntegral}}积分
          </div>
        </div>
        <div class="answer-content">
          <div class="topic">{{question.question}}</div>
          <div class="options">
            <div class="item" v-for="(item,index) in question.optionList" :key="index" @click="checkOptions(index)">
              <div class="prefix" :class="{checked: index == currentCheckedIndex}">{{ serialNum[index] }}</div>
              <div>{{ item.optionContent }}</div>
            </div>
          </div>
        </div>
        <div class="btn">
          <img src="../assets/submit.png" @click="onSubmit" alt="">
        </div>
      </div>
    </div>
    <CorrectDialog :config="correctDatas" @close="onClose"></CorrectDialog>
    <MissDialog :config="missDatas" @close="onClose"></MissDialog>
    <ComplateDialog :config="complateDatas"></ComplateDialog>
  </div>
</template>

<script>
  import CorrectDialog from './CorrectDialog';
  import MissDialog from './MissDialog';
  import ComplateDialog from './ComplateDialog'
  export default {
    components: {
      CorrectDialog, MissDialog, ComplateDialog
    },
    data() {
      return {
        correctDatas: {
          show: false
        },
        missDatas: {
          show: false
        },
        complateDatas: {
          show: false
        },
        serialNum: ['A','B','C','D'],
        answer: '',
        options: [],
        question: '',
        taskIntegral: '',
        taskStory: '',
        currentCheckedIndex: ''
      }
    }, 
    mounted () {
      this.scriptDetail = JSON.parse(sessionStorage.getItem('playCurrentScript'));
      let stepDatas = this.scriptDetail.stepList[this.$route.query.stepNo-1]
      this.taskStory = stepDatas.taskStory;
      this.question = stepDatas.questionResult;
      this.taskIntegral = stepDatas.taskIntegral;
      this.answer = question.answer;
    },
    methods: {
      onSubmit() {
        if(this.answer == this.currentCheckedIndex) {
          this.correctDatas = Object.assign(this.correctDatas, {
            show: true
          })
        }else {
          this.missDatas = Object.assign(this.missDatas, {
            show: true
          })
        }
      },
      checkOptions(index) {
        this.currentCheckedIndex = index;

      },
      onClose() {
        this.$router.push({path: '/game-center',query: {taskComplateStatus: true,scriptIndex: this.$route.query.scriptIndex}})
      }
    }
  }
</script>

<style lang="less" scoped>
  .answer {
    .main {
      padding: 20px 50px;
      .wrapper {
        padding: 120px 60px;
        height: 887px;
        background: url('../assets/task-border-bg.png')no-repeat;
        .answer-tip {
          padding: 30px 60px;
          background: rgba(61,74,97,.6);
          font-size: 24px;
          letter-spacing: 2px;
          border-radius: 10px;
        }
        .score-wrap {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          .mark {
            padding: 10px 38px;
            margin-right: 36px;
            font-size: 24px; 
            background: #B3D7FF;
            color: #333;
            border-radius: 16px; 
          }
          .score {
            display: flex;
            align-items: center;
            font-size: 24px;
          }
        }
        .answer-content {
          margin-top: 40px;
          font-size: 24px;
          .options {
            margin-top: 50px;
            display: flex;
            align-items: center;
            .item {
              width: 25%;
              display: flex;
              align-items: center;
              .prefix {
                margin-right: 12px;
                width: 50px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                background: #182326;
                border: 2px solid #B3D7FF;
                border-radius: 50%;
                transition: all 220ms linear;
              }
              .checked {
                background:#B3D7FF;
                color: #333;
              }
            }
          }
        }
        .btn {
          position: absolute;
          left: 0;
          bottom: 60px;
          width: 100%;
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }
</style>