<template>
  <div v-show="config.show" class="place-dialog">
    <!-- 详情面板 -->
    <div class="container animate__animated animate__faster" :class="[config.aniShow ? 'animate__slideInUp': 'animate__slideOutDown']">
      <div class="title">{{ config.placeDatas.scriptPlaceResultBo.placeName }}</div>
      <div class="scroll-wrap" style="height: 640px; overflow-y: scroll;">
        <div class="content" v-html="config.placeDatas.scriptPlaceResultBo.placeInformation"></div>
        <div class="pic-list" @click="openPreviewDialog">
          <img v-for="(item,index) of config.placeDatas.scriptPlaceResultBo.placeImages" :src="item" alt="">
        </div>
        <div v-if="config.placeDatas.scriptPlaceResultBo.placeVideoUrl != ''" class="video-wrap">
          <video :src="config.placeDatas.scriptPlaceResultBo.placeVideoUrl"></video>
        </div>
      </div>
      <div class="btn-wrap" @click="gotoHere">
        <img src="../assets/go-to.png" alt="">
      </div>
      <!-- 关闭按钮 -->
      <div class="close-btn" @click="config.show = false">
        <img src="../assets/close-btn-w.png" alt="">
      </div>
    </div>
    <!-- 图片面板 -->
    <div v-show="showPreview" class="img-panel animate__animated animate__faster" :class="[showPreview? 'animate__fadeInLeft': 'animate__fadeOutLeft']">
      <div class="img-preview">
        <img :src="currentPreviewImg" alt="">
      </div>
      <div class="img-list">
        <img 
          :class="selectImgIndex === index ? 'active': ''"
          v-for="(item,index) of config.placeDatas.scriptPlaceResultBo.placeImages" 
          :src="item" alt=""
          @click="selectPreviewImage(index)"
        >
       
      </div>
      <!-- 关闭按钮 -->
      <div class="img-close-btn" @click="showPreview = false">
        <img src="../assets/close-btn-w.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      config: {
        show: true,
        aniShow: true,
        placeDatas: ''
      }
    },
    data () {
      return {
        showPreview: false,
        currentPreviewImg: '',
        selectImgIndex: ''
      }
    },
    watch: {
      showPreview(newVal) {
        if(newVal){
          this.currentPreviewImg = this.config.placeDatas.scriptPlaceResultBo.placeImages[0];
          this.selectImgIndex = 0;
        }
      }
    },
    mounted () {
      
    },
    methods: {
      openPreviewDialog() {
        this.showPreview = true
      },
      selectPreviewImage(index){
        this.selectImgIndex = index;
        this.currentPreviewImg = this.config.placeDatas.scriptPlaceResultBo.placeImages[index]
      },
      gotoHere() {
        this.$emit('gotoHere')
      }
    }
  }
</script>

<style lang="less" scoped>
  .place-dialog {
    .container {
      position: absolute;
      bottom: 20px;
      left: 60px;
      width: 492px;
      height: 847px;
      padding: 40px 40px 0;
      box-sizing: border-box;
      background: url('../assets/script-detail-bg.png')no-repeat;
      background-size: 100%;
      border-radius: 20px;
      overflow: hidden;
      .title {
        text-align: center;
        font-size: 26px;
      }
      .scroll-wrap::-webkit-scrollbar {
        display: none;
      }
      .content {
        margin-top: 20px;
        font-size: 20px;
        line-height: 34px;
        text-indent: 2em;
      }
      .pic-list {
        margin-top: 20px;
        display: flex;
        overflow-x: scroll;
        img {
          margin-right: 24px;
          width: 110px;
          height: 110px;
          border-radius: 14px;
          object-fit: cover;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .video-wrap {
        margin-top: 40px;
        video {
          width: 100%;
          height: 200px;
        }
      }
      .btn-wrap {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        text-align: center;
        margin-top: 30px;
      }
      .close-btn {
        position: absolute;
        top: 36px;
        right: 20px;
      }
      .home-map {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .img-panel {
      position: absolute;
      bottom: 22px;
      left: 660px;
      width: 702px;
      height: 834px;
      padding: 40px 40px 0;
      box-sizing: border-box;
      border-radius: 20px;
      border: 6px solid #B3D7FF;
      background: rgba(20, 26, 31, 0.9);
      .img-preview {
        margin: 50px auto;
        width: 500px;
        height: 500px;
        background: #232D2C;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .img-list {
        margin-top: 60px;
        display: flex;
        overflow-x: scroll;
        img {
          margin-right: 24px;
          width: 110px;
          height: 110px;
          border-radius: 14px;
          border: 2px solid transparent;
          &.active {
            border: 2px solid #B3D7FF;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .img-close-btn {
        position: absolute;
        top: 26px;
        right: 26px;
      }
    }
  }
</style>