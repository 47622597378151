<template>
  <div v-show="datas.show" class="slide-preview">
    <div class="close-btn" @click="onClose">
      <img src="../assets/close-btn-w.png" alt="" width="50px">
    </div>
    <div class="swiper mySwiper animate__animated animate__faster" :class="[datas.show ? 'animate__zoomIn': 'animate__zoomOut']">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img :src="currentShowImg" alt="" style="width: 60%;">
        </div>
      </div>
      <div class="slide-box">
        <div class="s-prev" @click="onPrev">
          <img src="../assets/left-icon.png" width="100"  alt="">
        </div>
        <div class="s-next" @click="onNext">
          <img src="../assets/right-icon.png" width="100" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  export default {
    props: {
      datas: {
        show: false,
        imgsList: [],
        index: ''
      }
    },
    data () {
      return {
        imgsList: [],
        currentShowImg: '',
        msg: '123'
      }
    },
    watch: {
      'datas.show': {
        handler(newVal) {
          this.imgsList = this.datas.imgsList;
          this.index = this.datas.index;
          this.currentShowImg = this.datas.imgsList[this.datas.index];
        },
        immediate: true
      },
    },
    mounted () {
      
    },
    methods: {
      onPrev() {
        if(this.index !=0) {
          this.index--;
          this.currentShowImg = this.datas.imgsList[this.index];
        }
        
      },
      onNext() {
        if(this.index < this.datas.imgsList.length-1) {
          this.index++;
          this.currentShowImg = this.datas.imgsList[this.index];
        }
        
      },
      onClose() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .slide-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 40px 0 110px;
    z-index: 999;
    width: 70%;
    height: 80%;
    box-sizing: border-box;
    background: rgba(0,0,0,.5);
    border-radius: 6px;
    backdrop-filter: blur(13px);
  }
  .swiper {
    width: 100%;
    height: 100%;
    .slide-box {
      position: absolute;
      top: 50%;
      margin-top: -50px;
      z-index: 9;
      padding: 0 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100px;
      box-sizing: border-box;
    }
  }
  .swiper-wrapper {
    height: 100%;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
  }
  .close-btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -50px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border: 4px solid #c8e2fc81;
    border-radius: 50%;
  }
</style>